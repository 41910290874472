import React from 'react';
import './Polaroid.css';

function Polaroid(object) {

    return (
        <div className="polaroid" >
            <div>
                <img src={object.url} />
            </div>
            <div>
                <p>
                    {object.description}
                </p>
            </div>
        </div>

    )


}

export default Polaroid;


