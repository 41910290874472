
import './Icon.css';

function Icon(object) {
    const icon = object.object;
    // console.log('icons obj', icon);

    return (
        <li key={icon.id}><button onClick={() => {
            window.open(icon.url, "_blank");
          }}><img src={icon.image} alt={"social-icon-" + icon.id} /></button></li> 
    );

}

export default Icon; 