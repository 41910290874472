import React, { useRef, useState, Component } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Mousewheel, Pagination } from 'swiper/modules';

import Page from './components/Page';
// import Pages from './components/Pages';
import Nav from './components/Nav';
import Icon from './components/Icon';
import Reviews from './components/Reviews';
// import Icons from './Icons';

import './App.css';

import logo from './images/logo/logo-1-martina-regina.png'
import Portfolio from './components/Portfolio';


// import required modules


class App extends Component {


    constructor() {
        super();
        this.state = {
            nav: [{
                id: 0,
                name: 'Home'
            },
            {
                id: 1,
                name: 'Servizi'
            },
            {
                id: 2,
                name: 'Bio'
            },
            {
                id: 3,
                name: 'Portfolio'
            },
            {
                id: 4,
                name: 'Recensioni'
            },
            {
                id: 5,
                name: 'Contatti'
            }
            ],
            icons: [{
                id: 50,
                name: 'Instagram',
                image: 'https://cdn4.iconfinder.com/data/icons/social-media-black-white-2/600/Instagram_glyph_svg-512.png',
                url: 'https://www.instagram.com/martinaregina81/'
            },
            {
                id: 51,
                name: 'Facebook',
                image: 'https://cdn-icons-png.flaticon.com/512/2175/2175193.png',
                url: 'https://www.facebook.com/campaniello.martina/'
            },
            {
                id: 52,
                name: 'Linkedin',
                image: 'https://cdn-icons-png.flaticon.com/512/3128/3128219.png',
                url: ''
            }
            ],
            pages: [
                {
                    id: 1,
                    name: 'Servizi',
                    title: 'Trucco su Misura',
                    subtitle: 'Esalta la tua bellezza con stile personalizzato',
                    description: 'La mia passione è trasformare e valorizzare la bellezza naturale di ogni cliente. Offro servizi personalizzati di trucco per eventi speciali come matrimoni, shooting fotografici, feste ed altro ancora. Utilizzo solo prodotti di alta qualità per garantire risultati impeccabili e duraturi.',
                    images: [{
                        id: 12,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_3%2Fmatrimonio_3_6.JPG?alt=media&token=95f4da8d-e80d-4925-aa68-8fadc014682a'
                    },
                    {
                        id: 13,

                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_1%2Fmatrimonio_1_1.jpg?alt=media&token=cbd305b4-8538-49e9-806d-8aa55fda0241'
                    }
                    ]
                },
                {
                    id: 2,
                    name: 'Bio',
                    title: 'La Mia Storia',
                    subtitle: 'Passione, creatività e anni di esperienza',
                    description: "Sono un'artista del makeup con una fervida creatività e una vasta esperienza nel settore. La mia avventura nel mondo della bellezza è iniziata anni fa, alimentata dalla passione per l'arte e l'estetica. Ogni viso è una tela su cui dipingere, e amo creare look unici che riflettano la personalità e lo stile di ogni cliente.",
                    images: [{
                        id: 14,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_3%2Fmatrimonio_3_4.JPG?alt=media&token=8b5e5c74-f25e-4312-ade6-6a9835b4de81'
                    },
                    {
                        id: 15,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_2%2Fmatrimonio_2_1.jpg?alt=media&token=0eb97e1e-c92e-4097-a755-3c233aedc99a'
                    }
                    ]
                },
                {
                    id: 3,
                    name: 'Portfolio',
                    title: 'Visioni di Bellezza',
                    subtitle: 'Esplora il mio mondo di trucchi unici',
                    description: 'Nel mio portfolio troverai una selezione dei miei lavori più recenti e rappresentativi. Dalle eleganti mise da sposa ai trucchi audaci per editoriali di moda, ogni foto racconta una storia di bellezza e creatività. Sfoglia le immagini e lasciati ispirare per il tuo prossimo evento speciale.',
                    images: [{
                        id: 16,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/facepaint_1%2Ffacepaint_1_2.jpg?alt=media&token=53fe074e-b69a-45dd-ba36-f007d02bfc88'
                    },
                    {
                        id: 17,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/laurea_1%2Flaurea_1_1.jpg?alt=media&token=0a40b345-4d91-4598-953c-fa4a5603e093'
                    },
                    {
                        id: 18,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_2%2Fmatrimonio_2_2.jpg?alt=media&token=b8ebf67d-716c-4658-a19b-36d5aae3fd8f'
                    },
                    {
                        id: 9,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_3%2Fmatrimonio_3_1.JPG?alt=media&token=478f5ec4-35dd-4f31-be59-9b972fc7cafa'
                    },
                    {
                        id: 20,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/socials_1%2Fsocials_1_1.jpg?alt=media&token=fc0e7716-82c0-46be-afb5-01ba51a188db'
                    }
                    ]
                },
                {
                    id: 4,
                    name: 'Recensioni',
                    title: 'Voci Soddisfatte',
                    subtitle: 'Scopri cosa dicono i clienti felici',
                    description: 'Le opinioni dei miei clienti sono la mia migliore pubblicità. Leggi le recensioni per scoprire cosa dicono di me coloro che hanno già sperimentato i miei servizi. La soddisfazione dei clienti è la mia priorità assoluta, e mi impegno costantemente per garantire esperienze indimenticabili e risultati sorprendenti.',
                    images: [{
                        id: 20,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_1%2Fmatrimonio_1_1.jpg?alt=media&token=cbd305b4-8538-49e9-806d-8aa55fda0241'
                    },
                    {
                        id: 21,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_1%2Fmatrimonio_1_1.jpg?alt=media&token=cbd305b4-8538-49e9-806d-8aa55fda0241'
                    }
                    ]
                },
                {
                    id: 5,
                    name: 'Contatti',
                    title: 'Collegati con Me',
                    subtitle: 'Prenota il tuo appuntamento oggi stesso',
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut nibh felis. Pellentesque eleifend at dui tincidunt vulputate. Sed bibendum eros posuere, accumsan lectus sed, ultricies libero. Nam quis accumsan tortor, ac cursus ligula. Nulla ultrices ante eget magna lacinia, vitae dapibus lorem tincidunt. Nam a mattis metus. Suspendisse potenti. Nunc mi leo, sollicitudin nec euismod nec, hendrerit lacinia risus. Aenean sit amet laoreet libero, vel molestie nibh. Morbi quis viverra orci.',
                    images: [{
                        id: 18,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_1%2Fmatrimonio_1_1.jpg?alt=media&token=cbd305b4-8538-49e9-806d-8aa55fda0241'
                    },
                    {
                        id: 19,
                        url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_1%2Fmatrimonio_1_1.jpg?alt=media&token=cbd305b4-8538-49e9-806d-8aa55fda0241'
                    }
                    ]
                }],
            reviews: [
            {
                id: 0,
                name: 'Francesca',
                description: "Martina è fantastica. E' dolcissima, simpaticissima, ti mette a tuo agio ed è molto molto paziente. Martina una presenza solare e capace di rendere ancora più speciale il mio giorno più bello. Ha una grandissima passione per il suo lavoro. Super professionale e utilizza prodotti di alta qualità. Il trucco del mio matrimonio era bellissimo e duraturo alla perfezione sino alla fine. Non vedo l'ora di avere qualche evento per farmi truccare di nuovo da lei.",
                images: [{
                    id: 0,
                    url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_1%2Fmatrimonio_1_3.JPG?alt=media&token=e372e7cf-7eab-4860-a13e-caa50df1436b'
                }]
            },
            {
                id: 1,
                name: 'Valentina e Olivia',
                description: "Ho chiesto a  Martina di truccarmi per un party Hallowen a casa di amici, il tema scelto da mia figlia era la famiglia Addams, il risultato è stato spettacolare, Martina come sempre professionale a capire le nostre necessità. Mi sono sentita bellissima nel ruolo di Morticia e la mia bambina sembrava proprio la piccola Mercoledi!",
                images: [{
                    id: 0,
                    url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/halloween_1%2Fhalloween_1_3.jpg?alt=media&token=d5b00231-20f7-48dd-87ed-e6834b45f57c'
                }]
            },
            {
                id: 2,
                name: 'Hard Tone - producer',
                description: "Era la prima volta che lavoravo con Martina ed ho trovato in lei una persona preparata, appassionata, creativa e con la giusta capacità di fondere la sua creatività con le aspettative del cliente. Inoltre è una persona molto simpatica e cio contribuisce, soprattutto in caso di lavori complessi, a rendere l'atmosfera più rilassata.",
                images: [{
                    id: 0,
                    url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/facepaint_1%2Ffacepaint_1_2.jpg?alt=media&token=53fe074e-b69a-45dd-ba36-f007d02bfc88'
                }]
            },
            {
                id: 3,
                name: 'Anna',
                description: "Grazie davvero amica mia! Mi hai illuminato il viso e anche la giornata, hai fatto un miracolo. Ho ricevuto tantissimi complimenti per il trucco. Mi hai fatto rimanere me stessa ma con un tocco speciale. Non smetterò mai di ringraziarti, ogni volta che guardo le foto mi vedo bella e questo è certamente merito tuo!",
                images: [{
                    id: 0,
                    url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/laurea_1%2Flaurea_1_1.jpg?alt=media&token=0a40b345-4d91-4598-953c-fa4a5603e093'
                }]
            },
            {
                id: 4,
                name: 'Martina',
                description: "Ero in cerca di una truccatrice per il giorno più importante della mia vita e mi sono imbattuta in Martina, fin da subito ho visto in lei la persona giusta per questo arduo compito 😅 persona precisa e puntuale e subito ha saputo capire le mie idee e soprattutto metterle in pratica ricevendo in primis i miei complimenti e poi da parte di tutti gli invitati e parenti. Con il senno di poi nn avrei potuto scegliere di meglio! Professionista seria ed empatica che ha soddisfatto appieno le mie richieste! Grazie ancora per quanto fatto Martina!",
                images: [{
                    id: 0,
                    url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_4%2Fmartimonio_4_1.png?alt=media&token=ebd83d5f-ed34-4427-b7c9-410f74bdebf0'
                }]
            },
            {
                id: 5,
                name: 'Elena',
                description: "Ho conosciuto Martina tramite un amicizia in comune, sin dal primo momento ho capito che è una persona fantastica! Subito mi sono sentita a mio agio, io che sono un po' timida nel fare foto, stare in posa se qualcuno mi trucca, con Martina invece è stato tutto molto naturale e soprattutto divertente! Quando mi sono resa conto che mancava davvero pochissimo al mio arrivo in chiesa, Martina mi ha tranquillizzato e con la sua simpatia ha spazzato via quell' agitazione. Per il mio matrimonio ho scelto qualcosa di molto semplice e naturale essendo una persona che si trucca poco. E Martina ha capito e creato esattamente quello che volevo. Mi sono sentita bella come una principessa! Sceglierei Martina altre mille volte!",
                images: [{
                    id: 0,
                    url: 'https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/matrimonio_2%2Fmatrimonio_2_2.jpg?alt=media&token=b8ebf67d-716c-4658-a19b-36d5aae3fd8f'
                }]
            }]
        }
    }



    render() {
        return (
            <>
                <Swiper
                    direction={'vertical'}
                    // pagination={{
                    //     clickable: true,
                    // }}
                    // rewind={true}
                    modules={[Mousewheel]}
                    mousewheel={true}
                    className="mySwiper"
                    id="main-swiper"
                >
                    <SwiperSlide id="page-home">
                        <div className="content page-full ">
                            <div className="content-home">
                                <img className="home-content" id={"image-logo"} src={logo} alt="logo" />
                                {/* <Icons object={this.state.icons}></Icons> */}
                                <ul className="home-content social-icons">
                                    <Icon object={this.state.icons[0]}></Icon>
                                    <Icon object={this.state.icons[1]}></Icon>
                                    {/* <Icon object={this.state.icons[2]}></Icon> */}
                                </ul>
                                {/* <Nav object={this.state.nav}></Nav> */}
                                <ul className='main-nav font-lora'>
                                    <Nav object={this.state.nav[0]}></Nav>
                                    <Nav object={this.state.nav[1]}></Nav>
                                    <Nav object={this.state.nav[2]}></Nav>
                                    <Nav object={this.state.nav[3]}></Nav>
                                    <Nav object={this.state.nav[4]}></Nav>
                                    <Nav object={this.state.nav[5]}></Nav>
                                </ul>
                            </div>
                        </div>
                    </SwiperSlide>
                    {/* <Pages object={this.state.pages} /> */}
                    <SwiperSlide id="page-services">
                        <Page object={this.state.pages[0]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <Page object={this.state.pages[1]} />
                    </SwiperSlide>
                    <SwiperSlide>
                        {/* <Page object={this.state.pages[2]} /> */}
                        <Portfolio object={this.state.pages[2]}></Portfolio>
                    </SwiperSlide>

                    <SwiperSlide>
                        <Reviews object={this.state.reviews} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="content page-full footer">
                            <div id="footer-top">
                                <img id="footer-logo" src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/logo%2Fcorona-1.png?alt=media&token=26f60c65-4604-4faf-a720-bbc79b68c47b" />
                                <img id="footer-image-logo" src={logo} alt="logo" />
                            </div>
                            <div id="footer-bottom">
                                <ul className="contacts-icons">
                                    <li>
                                        <button onClick={() => window.location = 'mailto:martinaregina81@gmail.com'}>
                                            <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/icons%2Femail-svgrepo-com.svg?alt=media&token=a09088c0-e1c2-4e12-a224-db9acdc0f79c"></img>
                                        </button>
                                        <button onClick={() => window.location = 'mailto:martinaregina81@gmail.com'}><p>martinaregina81@gmail.com</p></button>
                                    </li>
                                    <li>
                                        <button onClick={() => window.location = 'tel:+393402545818'}>
                                            <img className="icon" src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/icons%2Fwhatsapp-svgrepo-com.svg?alt=media&token=4decc1f3-5583-4cb8-a867-8e0814c28e2b"></img>
                                        </button>
                                        <button onClick={() => window.location = 'tel:+393402545818'}><p>340 2545818</p></button>
                                    </li>
                                </ul>
                                {/* <div className="contacts-details">
                                    <button><p>martinaregina81@gmail.com</p></button>
                                    <button><p>+39 123 12345678</p></button>
                                </div> */}
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </>
        );
    }
}

export default App;
