import { useSwiper } from 'swiper/react';
import './Nav.css';

function Nav({ object }) {


    const swiper = useSwiper();
    // const links = object.object;
    const item = object;
    // console.log('nav obj', item);

    // return (
    //     <>
    //         <ul className='main-nav'>
    //             {
    //                 links.map((link, index) =>
    //                     <li key={link.id}><a onClick={() => swiper.slideTo(link.id)}>{link.name}</a></li>
    //                 )
    //             }
    //         </ul>
    //     </>
    // );

    return (
        <>
            <li key={item.id}>
                {/* <a href={"nav-" + item.id} onClick={() => swiper.slideTo(item.id)}>{item.name}</a> */}
                <button onClick={() => swiper.slideTo(item.id)}><p>{item.name}</p></button>
            </li>
        </>
    );

}

export default Nav; 