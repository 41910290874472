import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import './Reviews.css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';
import Polaroid from './Polaroid';

function Reviews(object) {

    let reviews = object.object;
    // console.log('review obj', reviews);

    return (
        <Swiper id="content-reviews" navigation={true} modules={[Navigation]}>
            {
                reviews.map((review, index) =>
                    <SwiperSlide key={review.id}>
                        <div className="review-item">
                            <div className="review-image">
                                <Polaroid
                                    url={review.images[0].url}
                                ></Polaroid>
                            </div>
                            {/* <div className="review-stars">
                            <img src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/icons%2Fstar-svgrepo-com.svg?alt=media&token=4df5502c-d5d7-4962-b68f-603b75867112"></img>
                            <img src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/icons%2Fstar-svgrepo-com.svg?alt=media&token=4df5502c-d5d7-4962-b68f-603b75867112"></img>
                            <img src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/icons%2Fstar-svgrepo-com.svg?alt=media&token=4df5502c-d5d7-4962-b68f-603b75867112"></img>
                            <img src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/icons%2Fstar-svgrepo-com.svg?alt=media&token=4df5502c-d5d7-4962-b68f-603b75867112"></img>
                            <img src="https://firebasestorage.googleapis.com/v0/b/regina-makeup.appspot.com/o/icons%2Fstar-svgrepo-com.svg?alt=media&token=4df5502c-d5d7-4962-b68f-603b75867112"></img>
                            </div> */}
                            <div className="review-body">
                                <h2>{review.name}</h2>
                                <p>{review.description}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                )
            }
        </Swiper>
    )


}

export default Reviews;


