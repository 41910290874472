import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import './Portfolio.css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

import Polaroid from './Polaroid';


function Portfolio(object) {

    // return (
    //     <div className="portfolio" >
    //         <div>
    //             <img src={object.url} />
    //         </div>
    //         <div>
    //             <p>
    //                 {object.description}
    //             </p>
    //         </div>
    //     </div>
    // )
    let portfolio = object.object;
    // console.log('review obj', reviews);

    const photos = portfolio.images.reduce(function (rows, key, index) {
        return (index % 2 == 0 ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows;
    }, []);

    return (
        <Swiper id="content-portfolio" navigation={true} modules={[Navigation]}>
            {
                photos.map((items, index) =>
                    <div className="portfolio-item">
                        <SwiperSlide key={portfolio.id} className="swiper-portfolio">
                            {
                                items.map(item => (
                                    <Polaroid
                                        url={item.url}
                                        // description={item.id}
                                    ></Polaroid>

                                ))
                            }
                        </SwiperSlide>
                    </div>
                )
            }
        </Swiper>
    )


}

export default Portfolio;


