import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';



import './Page.css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';
import Polaroid from './Polaroid';

function Page(object) {

    useEffect(() => {
        document.title = 'Martina Regina Makeup';
    }, []);


    let page = object.object;
    // console.log('pages obj', page);

    return (
        <>
            {/* <div className="page-wrapper"> */}
                <div key={page.id} className="content-text">
                    <h3>{page.name}</h3>
                    <h1>{page.title}</h1>
                    <h2>{page.subtitle}</h2>
                    <p>{page.description}</p>
                </div>
                {
                    <Swiper className="content-images" navigation={true} modules={[Navigation]}>
                        {
                            page.images.map((image, index) =>
                                <SwiperSlide key={image.id}>
                                    <div>
                                        <Polaroid url={image.url}></Polaroid>
                                    </div>
                                </SwiperSlide>
                            )
                        }
                    </Swiper>
                }
            {/* </div> */}
        </>
    )


}

export default Page;


